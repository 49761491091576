import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';
// import {AuthService} from 'angularx-social-login';
// FacebookLoginProvider, GoogleLoginProvider
const headers = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  public user;

  constructor(private  http: HttpClient, /*private authService: AuthService*/) {

  }

  // signInWithGoogle() {
  //   return this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }
  //
  // signInWithFB() {
  //   return this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }
  //
  // signOut() {
  //   return this.authService.signOut();
  // }


  userStatus() {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'user-status', {...this.getBody(), device_key});
  }

  login(password) {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'user-login', {...this.getBody(), password, device_key});
  }

  socialLogin(data = {}) {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'user-social-login', {...data, device_key});
  }

  /*  sendMobileNumberOtp(mobileOrEmail) {
      return this.http.post<any>(environment.easy_core + 'login_s1', {mobileOrEmail});
    }

    sendOtpForLogin(mobile, otp) {
      return this.http.post<any>(environment.easy_core + 'login_status', {mobile, otp});
    }

    sendMobileNumberOtpAgain(mobile) {
      return this.http.post<any>(environment.easy_core + 'login_status', {mobile});
    }*/

  loginStatus(token) {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'login-status', {token, device_key});
  }

  registration(data) {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'registration', {...data, device_key});
  }

  otpSubmit(body) {
    //console.log(body);
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'otp-verification', {...body, device_key});
  }

  logout(token?) {
    token = localStorage.getItem('token');
    const device_key = localStorage.getItem('device_key');
    // this.signOut();
    return this.http.post<any>(environment.easy_core + 'logout', {token, device_key});
  }

  logoutFromAllDevices(token) {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'logout-from-all-devices', {token, device_key});
  }

  getBody() {
    this.user = JSON.parse(localStorage.getItem('user'));
    let body: {};
    if (this.user && this.user.mobile) {
      body = {mobile: this.user.mobile};
    }
    if (this.user && this.user.email) {
      body = {email: this.user.email};
    }
    return body;
  }

  resdOtp(body) {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'resend-otp', {...body, device_key});
  }

  forgotPasswordOTPSend(body) {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'forgot-password-otp', {...body, device_key});
  }

  forgotPasswordRestore(body) {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'forgot-password-restore', {...body, device_key});
  }

  mobileNumberUpdateForOldUser(body) {
    const device_key = localStorage.getItem('device_key');
    return this.http.post<any>(environment.easy_core + 'old-user-otp-for-mobile-restore', {...body, device_key});
  }
}

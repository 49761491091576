import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {map} from 'rxjs/operators';
import {CommonService} from '../../services/common.service';


@Injectable()
export class CoreInterceptor implements HttpInterceptor {
  constructor(private readonly commonService: CommonService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.easy_core) !== -1) {
      const headers = {lang: localStorage.getItem('lang') || 'en'};
      if (localStorage.getItem('token')) {
        // @ts-ignore
        headers.token = localStorage.getItem('token');
      }
      if (localStorage.getItem('device_key')) {
        // @ts-ignore
        headers['device-key'] = localStorage.getItem('device_key');
      }
      request = request.clone({
        setHeaders: headers
      });
    }
    return next.handle(request).pipe(map((response) => {
      // @ts-ignore
      if (response && response.url && response.url.indexOf(environment.easy_core) !== -1) {
        // @ts-ignore
        if (response && response.body && response.body.code === 401) {
          this.commonService.logout();
        }
      }
      return response;
    }));
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
 
import {DataTablesModule} from 'angular-datatables';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './common-components/footer/footer.component';
import {NavComponent} from './common-components/nav/nav.component';
import {MainLayoutComponent} from './common-components/main-layout/main-layout.component';
import {NotFoundComponent} from './common-components/not-found/not-found.component';
import {ExclusiveOffersComponent} from './common-components/exclusive-offers/exclusive-offers.component';
import {LoginComponent} from './common-components/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {RechargeModule} from './modules/recharge/recharge.module';
import {EmailOrMobileDirective} from './validators/email-or-mobile.directive';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {ErrorPopupComponent} from './modals/error-popup/error-popup.component';
import {SuccessPopupComponent} from './modals/success-popup/success-popup.component';
import {ConfirmationPopupComponent} from './modals/confirmation-popup/confirmation-popup.component';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material';
import {Ng2PageScrollModule} from 'ng2-page-scroll';
import {DashboardLayoutComponent} from './common-components/dashboard-layout/dashboard-layout.component';
import {AppStoreComponent} from './common-components/app-store/app-store.component';
import {AuthGuard} from './guards/auth.guard';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {MovieOffersComponent} from './common-components/movie-offers/movie-offers.component';
import {RechargeOfferComponent} from './modules/recharge/recharge-offer/recharge-offer.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {SharedModule} from './shared/shared.module';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import {LangSharedTranslateModule} from './modules/translate/lang-shared-translate/lang-shared-translate.module';
import {AnnouncementsComponent} from './common-components/announcements/announcements.component';
import {NgInitDirective} from './directives/ng-init.directive';
import {CoreInterceptor} from './shared/interceptor/core.interceptor';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {MessagingService} from './services/messaging.service';
import {AsyncPipe} from '@angular/common';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from 'angularx-social-login';
import {PromotionalModalComponent} from "./modals/promotional-modal/promotional-modal.component";
import { CorporateSpecialPackAmountsComponent } from './common-components/corporate-special-pack-amounts/corporate-special-pack-amounts.component';


const config = {
  provide: 'SocialAuthServiceConfig',
  useValue: {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.google_client_id),
      },
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(environment.facebook_app_id),
      },
    ],
  } as SocialAuthServiceConfig,
};

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavComponent,
    MainLayoutComponent,
    NotFoundComponent,
    ExclusiveOffersComponent,
    LoginComponent,
    EmailOrMobileDirective,
    ErrorPopupComponent,
    SuccessPopupComponent,
    ConfirmationPopupComponent,
    DashboardLayoutComponent,
    AppStoreComponent,
    MovieOffersComponent,
    RechargeOfferComponent,
    AnnouncementsComponent,
    NgInitDirective,
    PromotionalModalComponent,
    CorporateSpecialPackAmountsComponent,

  ], 
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    RechargeModule,
    FormsModule,
    DeviceDetectorModule.forRoot(),
    LoadingBarHttpClientModule,
    RxReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    MatDialogModule,
    Ng2PageScrollModule,
    environment.production ? ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}) : [],
    CarouselModule,
    NgxPaginationModule,
    SharedModule,
    FormlyModule.forRoot({extras: {lazyRender: true}}),
    FormlyBootstrapModule,
    LangSharedTranslateModule,
    SocialLoginModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    DataTablesModule.forRoot()
  ],
  providers: [
    config,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreInterceptor,
      multi: true
    },
    AuthGuard,
    MessagingService,
    AsyncPipe,
  ],
  exports: [],
  bootstrap: [AppComponent],
  entryComponents: [SuccessPopupComponent, ErrorPopupComponent, ConfirmationPopupComponent, PromotionalModalComponent]
})
export class AppModule {
}

import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LoginStatusService } from '../../../services/login-status.service';
import { ECPopupService } from '../../../services/ecpopup.service';
import { CommonService } from '../../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { TopupService } from '../../../services/recharge/topup.service';
import { Topupoffer } from '../../../model/topupoffer.interface';
import { LoginService } from '../../../services/login.service';
import { isPlatformBrowser } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { RechargeRequestModalComponent } from "../recharge-request-modal/recharge-request-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { RechargeRequestModalService } from "../recharge-request-modal/recharge-request-modal-service";
import { PromotionalModalComponent } from "../../../modals/promotional-modal/promotional-modal.component";
import { environment } from '../../../../environments/environment.prod';

declare var $: any;

@Component({
  selector: 'app-top-up',
  templateUrl: './top-up.component.html',
  styleUrls: ['./top-up.component.scss']
})
export class TopUpComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {

  previousRating = 0;
  isOfferAllow = true;

  constructor(
    private cdref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private loginStatusService: LoginStatusService,
    private ecpopup: ECPopupService,
    private commonService: CommonService,
    private activatedRouter: ActivatedRoute,
    private topupService: TopupService,
    private loginService: LoginService,
    private router: Router,
    private topUpService: TopupService,
    private dialog: MatDialog,
    private rechargeRequestModalService: RechargeRequestModalService,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService) {
    this.forms = [1, 2, 3];
    this.token = isPlatformBrowser(this.platformId) ? localStorage.getItem('token') || '' : '';
    this.detectLangChange();
  }

  onDestroy$: Subject<void> = new Subject<void>();
  forms: any[] = [];
  orderForm: FormGroup;
  items: FormArray;
  token: string;
  sumAmount: number;
  transactions: {
    name: string;
    phone: string,
    amount: number,
    isSelect?: boolean,
    alreadySave?: boolean,
    operator: number,
    type: string,
    is_for_add_fav: boolean,
    bonus_amount: number,
    bonus_percentage: number,
    campaign_name: string,
    transaction_id: string,
    status: string,
  }[] = [];
  transactionStatus: string;
  favouriteData: { phone: string, name: string, connection_type: string, operator_id: string }[] = [];
  mobileRx: RegExp = /(\+88|88)?01[3456789][0-9]{8}/;
  offerRechargeData: Topupoffer[] = [];
  operatorShortNameList = { GP: 1, BL: 2, RB: 3, TT: 5, AB: 6 };
  isTopupActive = true;
  isLoggedIn = false;
  bestOffer: Topupoffer;
  offerForYou: Topupoffer[] = [];
  is_schedule_recharge = false;
  api_res_schedule_time = null;
  payment_button_disable_by_process = false;
  isFilter = false;
  currentRate = 5;
  currentLang = 'en';

  detectLangChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.is_schedule_recharge = false;
      this.initFunction(this.translate.instant('daterangepicker.cancel'), this.translate.instant('daterangepicker.apply'));
    });
  }

  ngOnInit() {
    this.commonService.currentLanguage.pipe(takeUntil(this.onDestroy$)).subscribe(el => this.currentLang = el);
    this.commonService.serviceListSubject.pipe(takeUntil(this.onDestroy$)).subscribe(el => {
      if (el) {
        el.forEach(e => {
          if (e.title === 'top-up') {
            this.isTopupActive = e.is_active == 1;
          }
        });
      }
    });
    const queryParams = this.activatedRouter.snapshot.queryParams;
    this.loginStatusService.loginStatusGet().pipe(takeUntil(this.onDestroy$)).subscribe(status => {
      this.isLoggedIn = status;
      if (status) {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('token')
          && localStorage.getItem('user')
          && JSON.parse(localStorage.getItem('user'))
          && (JSON.parse(localStorage.getItem('user'))).mobile) {
          this.topupService.favouriteContact((JSON.parse(localStorage.getItem('user'))).mobile).pipe(takeUntil(this.onDestroy$)).subscribe(
            res => {
              if (res.code === 200 || res.status === 'success') {
                this.favouriteData = res.data;
              }
            },
            err => {

            },
            () => {
              if (queryParams.tran_id) {
                this.ecpopup.paymentStatus(queryParams.tran_id).pipe(takeUntil(this.onDestroy$)).subscribe(
                  res => {
                    if (res.code === 200) {
                      this.transactions = JSON.parse(res.data.order_data).map(el => {
                        const alreadySave = this.favouriteData.findIndex(e => e.phone.substr(-11) === el.phone.substr(-11));
                        el.alreadySave = alreadySave !== -1;
                        el.isSelect = false;
                        el.is_for_add_fav = false;
                        if (el.status === 'hold') {
                          el.amount = el.amount - el.bonus_amount;
                        }
                        return el;
                      });
                      this.sumAmount = res.data.paid_amount;
                      this.previousRating = res.data.rating;
                      if (res.data && ((res.data.status || '').toLowerCase() !== 'success' && (res.data.payment_status || '').toLowerCase() !== 'valid')) {
                        if (isPlatformBrowser(this.platformId)) {
                          $('#recharge_fail_cancel').modal('show');
                        }
                        this.transactionStatus = 'fail';
                      } else {
                        if (res.data.recharge_completed_at == null && res.data.service_schedule_at != null) {
                          this.api_res_schedule_time = new Date(res.data.service_schedule_at).toLocaleString();
                        } else {
                          this.api_res_schedule_time = null;
                        }
                        this.transactionStatus = 'success';
                        if (isPlatformBrowser(this.platformId)) {
                          $('#recharge_success').modal('show');
                        }
                      }

                    } else {
                      if (res.code === 401) {
                        this.loginService.logout();
                        this.commonService.confirmationPopup(this.translate.instant('popup.login'), this.translate.instant('popup.is_login')).afterClosed().subscribe(
                          r => {
                            if (r === 'Yes') {
                              if (isPlatformBrowser(this.platformId)) {
                                $('#loginModal').modal('show');
                              }
                            }
                          }
                        );
                      } else {
                        this.commonService.errorPopup(
                          res.status || this.translate.instant('popup.error') + '!', res.message || this.translate.instant('popup.transaction_failed_try_again'));
                      }
                    }
                  },
                  err => {
                  },
                  () => {

                  }
                );
              }
            }
          );
        }
      }
      this.activatedRouter && this.activatedRouter.params && this.activatedRouter.params.subscribe(queryParams => {

        if (queryParams.ruid) {
          localStorage.removeItem('ruid');
          localStorage.setItem('ruid', queryParams.ruid);
        } else {
          this.http.get(environment.easy_core + 'get-promotion-banner' + `?device_type=web`).subscribe((res: any) => {

            if (res.code === 200 && res.data.promotion_is_active === true) {
              this.getPromotionalModal(res.data)
            }

          })

        }
        const ruid = localStorage.getItem('ruid');
        if (ruid) {
          this.rechargeRequestModalService.rechargeRequestInfo(ruid).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
            if (res.code === 200) {
              if (this.isLoggedIn) {
                let is_not_same_user = true;
                const user = this.rechargeRequestModalService.getUserData();
                if (user && user.mobile_verified) {
                  if (user.mobile === res.data.request_body.requested_identifier) {
                    is_not_same_user = false;
                  }
                }
                if (user && user.email_verified) {
                  if (user.email === res.data.request_body.requested_identifier) {
                    is_not_same_user = false;
                  }
                }
                if (is_not_same_user) {
                  this.commonService.logout();
                  // window.location.reload();
                } else {
                  this.router.navigate(['dashboard', 'recharge-request']);
                }
              } else {
                if (res.data.requested) {
                  localStorage.setItem('requested_identifier', res.data.request_body.requested_identifier);
                  this.rechargeRequestModalService.setMobileOrEmailForRechargeRequest(res.data.request_body.requested_identifier);
                  $('#loginModal').modal('show');
                } else {
                  localStorage.setItem('requested_identifier', res.data.request_body.requested_identifier);
                  this.rechargeRequestModalService.setRequestBody({
                    email: isNaN(res.data.request_body.requested_identifier) ? res.data.request_body.requested_identifier : '',
                    mobile: isNaN(res.data.request_body.requested_identifier) ? '' : res.data.request_body.requested_identifier,
                  });
                }
              }
            }
          });
        }
      })


    });


    this.orderForm = this.formBuilder.group({
      schedule_recharge_top_up: ['', []],
      items: this.formBuilder.array([this.createItem({})])
    });
    this.items = this.orderForm.get('items') as FormArray;

    this.topupService.offerRecharge.pipe(takeUntil(this.onDestroy$)).subscribe(
      r => {
        let res = [...r];
        // setTimeout(() => {
        if (this.isFilter) {
          let equal = true;
          this.bestOffer = undefined;
          const type = this.items.controls[this.items.controls.length - 1].get('type').value;
          res = res.filter(d3 => {

            let tempType = '';
            if (+d3.is_for_postpaid === 1 && type === 'postpaid') {
              tempType = 'postpaid';
            }
            if (+d3.is_for_prepaid === 1 && type === 'prepaid') {
              tempType = 'prepaid';
            }
            return !(type && type !== tempType);
          }).filter(d2 => !(this.items.controls[this.items.controls.length - 1].get('amount').value && +this.items.controls[this.items.controls.length - 1].get('amount').value > +d2.amount_max));
          let i = 0;
          this.offerRechargeData.length = 0;
          if (this.items.controls[this.items.controls.length - 1].get('amount').value <= 0) {
            res.sort((f1, f2) => {
              return +f2.usage_cnt - +f1.usage_cnt;
            });
            res.sort((f1, f2) => {
              return +f2.is_popular - +f1.is_popular;
            });
          }
          for (const d of res) {
            i++;
            if (i <= 4) {
              this.offerRechargeData.push(d);
            }
            if (equal) {
              if (this.items.controls[this.items.controls.length - 1].get('amount').value === d.amount_max) {
                equal = false;
                this.bestOffer = undefined;
              }
              if (this.items.controls[this.items.controls.length - 1].get('amount').value < d.amount_max) {
                this.bestOffer = d;
                equal = false;
              }
            }
            if (!equal && i > 4) {
              break;
            }
          }
        } else {
          this.isFilter = true;
          // this.bestOffer = undefined;
        }
        // }, 100);

      }
    );
    this.topupService.offerRechargeSelect.subscribe(
      res => {
        if (res) {
          this.items.controls[this.items.controls.length - 1].patchValue({
            phone: this.items.controls[this.items.controls.length - 1].get('phone').value,
            amount: +res.amount_max || +res.amount_min,
            type: +res.is_for_prepaid === 1 ? 'prepaid' : 'postpaid',
            operator: this.operatorShortNameList[res.operator_short_name]
              || this.items.controls[this.items.controls.length - 1].get('operator').value
          });
          this.items.controls[this.items.controls.length - 1].get('amount').updateValueAndValidity();
          this.setOfferData();
        }
      }
    );
    this.OfferDataFilter(null, null, null, null);

    this.orderForm.valueChanges.subscribe(selectedValue => {
      if (selectedValue && selectedValue.items) {
        if (selectedValue.items.length === 1) { 
          let data: any = selectedValue.items[0];

          if (data && data.phone && data.operator && data.type === "prepaid") {
            this.topupService.offerForYou(data).subscribe(res => {
              if (res && res.data) {
                if (selectedValue.items.length !== 1) this.offerForYou = [];
                this.offerForYou = res.data;
              } else this.offerForYou = [];
            })
          } else {
            this.offerForYou = [];
          }
        } else {
          this.offerForYou = [];
        }
      } else {
        this.offerForYou = [];
      };
    })
  }

  getPromotionalModal(data: any) {
    //console.log(data); 
    const p_m_flag = parseInt(localStorage.getItem('p-m-flag') || '0');
    const hour: number = new Date().getTime();// current time
    //console.log("p_m_flag : " + p_m_flag + " hour : " + hour)
    if (p_m_flag == 0 || p_m_flag < hour) {
      // const addHour: number = new Date(new Date().setTime((new Date().getTime() + 3600 * 1000))).getTime(); // 1 hour added
      const addMinute: number = new Date(new Date().setTime((new Date().getTime() + data.duration_limit_in_minute * 60 * 1000))).getTime(); // 1 minute added
      //console.log("addMinute: " + addMinute)
      localStorage.setItem('p-m-flag', (addMinute) + ''); //promotional modal logic
      const dialogRefer = this.dialog.open(PromotionalModalComponent, {
        width: '850px',
        data: data
      });
    }
  }
  setOfferData(input?, index?) {
    if (input && input === 'type') {
      this.items.controls[Math.min(index || 0, this.items.controls.length - 1)].get('amount').setValue('');
    }
    this.OfferDataFilter(
      this.items.controls[this.items.controls.length - 1].get('operator').value,
      this.items.controls[this.items.controls.length - 1].get('amount').value,
      this.items.controls[this.items.controls.length - 1].get('type').value, null);
  }

  OfferDataFilter(operatorId, amount, type, id) {
    this.topupService.getOfferRechargeListByFilter(operatorId, amount, type, id);
  }

  setOfferRechargeDetails(data) {
    this.isFilter = false;
    this.bestOffer = data;
    this.topupService.offerRechargeSelect.next(data);
  }

  handelOfferForYou(data) {
    let fItem : any = this.orderForm.getRawValue() && this.orderForm.getRawValue().items && this.orderForm.getRawValue().items.length ? this.orderForm.getRawValue().items[0] : null;
    
    let formData = {
      device_key: localStorage.getItem('device_key'),
      is_mobile:0,
      operator: parseInt(fItem.operator),
      msisdn: fItem.phone,
      ticket_id: data.ticket_id,
      amount: data.amount,
    };

    if (!this.isLoggedIn) {
      $('#loginModal').modal('show');
      return false;
    }
    
    this.topupService.processOfferForYou(formData).subscribe(res => {
      if (res.code === 200 || res.status === 'success') {
        this.ecpopup.loadModal({ url: res.data, logo: res.logo });
      }
    })
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => !term.length ? [] :
        this.favouriteData.filter(v => (v.name.toLowerCase().includes(term.toLowerCase()) || v.phone.includes(term))))
    );

  selectItem(event: any, i: number) {
    const item = event.item;
    setTimeout(() => {
      if (item.phone.length >= 11) {
        this.items.controls[i].get('phone').setValue(item.phone.substr(-11));
        this.items.controls[i].get('operator').setValue(item.operator_id);
        this.items.controls[i].get('type').setValue(item.connection_type);
        this.setOfferData();
      }
    }, 50);
  }

  createItem(data): FormGroup {
    return this.formBuilder.group({
      phone: [data.phone || '', [
        Validators.required,
        Validators.pattern(this.mobileRx),
        Validators.maxLength(11),
        RxwebValidators.unique()
      ]],
      amount: [+data.amount || '', [Validators.required, Validators.min(10), this.amountValidation.bind(this)]],
      operator: [data.operator || '', Validators.required],
      type: [data.type || '', Validators.required]
    });
  }

  amountValidation(control: AbstractControl): { [key: string]: any } | null {
    if (control.parent) {
      const type = control.parent.get('type').value;
      if (((type === 'prepaid' || type === 'skitto') && control.value >= 10 && control.value <= 1000) ||
        (type === 'postpaid' && control.value >= 10 && control.value <= 2500)) {
        return null;
      } else {
        return { amount: { value: control.value } };
      }
    }
    return { amount: { value: control.value } };
  }

  addItem(): void {
    this.items = this.orderForm.get('items') as FormArray;
    this.items.push(this.createItem({}));
    this.bestOffer = undefined;
    this.OfferDataFilter(null, null, null, null);
  }

  removeItems(i: number) {
    this.items.removeAt(i);
    this.OfferDataFilter(null, null, null, null);
  }

  changeConnectionType(i: number) {
    if (i !== this.items.controls.length - 1) {
      let message = this.translate.instant('modules.change_connection_type'); // @todo default message without offer
      for (const offer of this.topupService.data) {
        if (
          this.topupService.operatorMap[+this.items.controls[i].get('operator').value] === offer.operator_short_name &&
          this.items.controls[i].get('amount').value === offer.amount_max && (
            +offer.is_for_postpaid === 1 && this.items.controls[i].get('type').value === 'prepaid' ||
            +offer.is_for_prepaid === 1 && this.items.controls[i].get('type').value === 'postpaid')
        ) {
          message = this.translate.instant('modules.offer_not_available_postpaid');
          break;
        }
      }
      this.commonService.errorPopup(this.translate.instant('popup.caution') + '!', message, this.translate.instant('popup.ok'), false);
    }
    this.setOfferData();
  }

  setData(data) {
    this.items.removeAt(this.items.controls.length - 1);
    this.items.push(this.createItem(data));
  }

  submit(event) {
    this.payment_button_disable_by_process = this.isLoggedIn;
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('user') && localStorage.getItem('token')) {
      let sr = $('#schedule_recharge');
      const curDate = new Date().setDate(new Date().getDate() + 30);
      if (new Date(sr.val()) < new Date() || new Date(sr.val()).getTime() > curDate) {
        if (new Date(sr.val()) < new Date()) {
          this.commonService.errorPopup(this.translate.instant('popup.fail') + '!', this.translate.instant('popup.schedule_time_expire'));
        }
        if (new Date(sr.val()).getTime() > curDate) {
          this.commonService.errorPopup(this.translate.instant('popup.fail') + '!', this.translate.instant('popup.schedule_time_should_not_more_than_30_days'));
        }
        this.is_schedule_recharge = false;
        sr.val('');
        this.payment_button_disable_by_process = !this.isLoggedIn;
        return;
      }
      sr = $('#schedule_recharge').val();
      const items = [...this.items.value];
      /*.map(el => {
          el.operator = el.type === 'skitto' ? 13 : el.operator;
          el.type = el.type === 'skitto' ? 'prepaid' : el.type;
          return el;
        })*/
      this.ecpopup.topupinit({
        data: items,
        schedule_recharge: sr !== 'Schedule Recharge' ? sr : ''
      }).pipe(takeUntil(this.onDestroy$)).subscribe(
        res => {
          if (res.code === 200 || res.status === 'success') {
            this.ecpopup.loadModal({ url: res.data, logo: res.logo });
          } else {
            if (res.code === 401) {
              this.commonService.logout();
              if (isPlatformBrowser(this.platformId)) {
                $('#loginModal').modal('show');
              }
            } else {
              this.commonService.errorPopup(this.translate.instant('popup.fail') + '!', res.message || this.translate.instant('popup.went_wrong'));
            }
          }
        },
        errVR1 => {
          this.commonService.errorPopup(this.translate.instant('popup.error') + '!', this.translate.instant('popup.internal_error'));
        },
        () => {
          this.payment_button_disable_by_process = false;
        }
      );

    } else {
      if (isPlatformBrowser(this.platformId)) {
        $('#loginModal').modal('show');
      }
    }
  }

  checkListForSave(i) {
    this.transactions[i].isSelect = !this.transactions[i].isSelect;
  }

  initFunction(cancelLabel: string = 'Cancel', applyLabel: string = 'Apply') {

    if (isPlatformBrowser(this.platformId)) {
      $(() => {
        const sr = $('#schedule_recharge');
        sr.daterangepicker({
          singleDatePicker: true,
          startDate: new Date(),
          showDropdowns: true,
          timePicker: true,
          timePicker24Hour: false,
          timePickerIncrement: 1,
          autoUpdateInput: true,
          locale: {
            format: 'DD MMM YYYY hh:mm A',
            cancelLabel,
            applyLabel
          },
        });
        sr.val('');
        sr.on('cancel.daterangepicker', (ev, picker) => {
          sr.val('');
          this.is_schedule_recharge = false;
        });
        sr.change((e) => {
          if (new Date(sr.val()) > new Date()) {
            this.is_schedule_recharge = true;
          } else {
            sr.val('');
            this.is_schedule_recharge = false;
          }
        });
        sr.blur((e) => {
          sr.val('');
          this.is_schedule_recharge = false;
        });
      });
    }
  }

  ngAfterViewInit(): void {
    this.initFunction();
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  async done() {
    // this.transactions = this.transactions.filter(el => el.isSelect);
    // if (!this.transactions.length) {
    //   this.transactionRating();
    //   return;
    // }
    await this.transactionRating();
    if (this.transactions && this.transactions[0] && this.transactions[0].status === 'hold') {
      const transaction_id = this.transactions[0].transaction_id;
      this.commonService.httpPost('vr/initiate-hold-recharge-user', {
        transaction_id,
        allow_offer: this.isOfferAllow
      }).subscribe(res => {
        if (res.code === 200 || res.status === 'success') {
        }
      });
    }
  }

  transactionRating() {
    try {
      const queryParams = this.activatedRouter.snapshot.queryParams;
      return this.commonService.httpPost('user-feedback/transaction-rating', {
        transaction_id: queryParams.tran_id,
        rating: this.currentRate
      }).subscribe(res => {
      });
    } catch (e) {
    }
  }

  selectFavouriteNumber(i: number) {
    if (this.items.controls[i].get('phone').value.length == 11) {
      const favourite = this.favouriteData.filter(f => this.items.controls[i].get('phone').value === f.phone);
      if (favourite.length > 0) {
        const item = favourite[0];
        setTimeout(() => {
          if (item.phone.length >= 11) {
            this.items.controls[i].get('phone').setValue(item.phone.substr(-11));
            this.items.controls[i].get('operator').setValue(item.operator_id);
            this.items.controls[i].get('type').setValue(item.connection_type);
            this.setOfferData();
          }
        }, 50);
      }
    }
  }


  addFavouriteNumber(index) {
    try {
      this.topUpService.addFavouriteNumber({
        connection_type: this.transactions[index].type,
        name: this.transactions[index].name,
        operator_id: this.transactions[index].operator,
        phone: this.transactions[index].phone,
      }).pipe(takeUntil(this.onDestroy$)).subscribe(
        res => {
          if (res.code === 200 || res.status === 'success') {
            this.transactions[index].alreadySave = true;
          }
        }
      );
    } catch (e) {

    }
  }

  rechargeRequest() {
    const dialogRefer = this.dialog.open(RechargeRequestModalComponent, {
      width: '900px',
      data: { recharge: this.items.value[0], is_login: this.isLoggedIn }
    });
    // dialogRefer.componentInstance.head = head;
    // dialogRefer.componentInstance.message = message || this.translate.instant('popup.s_update');
  }
}



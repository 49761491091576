import {AfterContentChecked, Component, DoCheck, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {CommonService} from '../../services/common.service';
import * as Fingerprint2 from 'fingerprintjs2';
import {LoginStatusService} from '../../services/login-status.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {CommonAPIInterface} from '../../model/CommonAPI.interface';
import {MessagingService} from '../../services/messaging.service';

declare var $: any;

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, DoCheck, AfterContentChecked, OnDestroy {

  relativeUrl = '';
  onDestroy$: Subject<void> = new Subject<void>();
  currentLanguage: string;
  dynamicCommonServices: CommonAPIInterface[];
  fcm_token;

  constructor(
    private messagingService: MessagingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private commonService: CommonService,
    private loginStatus: LoginStatusService,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.getDeviceInfo();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('token')) {
      $('.inner').addClass('index');
      $('.index').removeClass('inner');
      this.commonService.loginStatus().pipe(takeUntil(this.onDestroy$)).subscribe(
        res => {
          if (res.code === 200 || res.status === 'success') {
            this.loginStatus.loginStatusSet(true);
            localStorage.setItem('user', JSON.stringify(res.data));
          } else {
            this.loginStatus.loginStatusSet(false);
          }
        }
      );
    } else {
      this.commonService.logout();
      this.loginStatus.loginStatusSet(false);
    }
    this.commonService.deviceKeyObservable.pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      if (res) {
        this.commonService.commonAPIList();
      }
    });
    this.commonService.commonAPIDynamicServices.pipe(takeUntil(this.onDestroy$)).subscribe(res => this.dynamicCommonServices = res);
    this.commonService.currentLanguage.pipe(takeUntil(this.onDestroy$)).subscribe(currentLanguage => this.currentLanguage = currentLanguage);
    this.messagingService.fcmToken.pipe(takeUntil(this.onDestroy$)).subscribe(fcm_token => this.fcm_token = fcm_token);
  }

  ngDoCheck(): void {
  }

  ngAfterContentChecked(): void {
    this.relativeUrl = this.router.url.split('/')[this.router.url.split('/').length - 1];
    if (this.relativeUrl.indexOf('?') !== -1) {
      this.relativeUrl = this.relativeUrl.substring(0, this.relativeUrl.indexOf('?'));
    }
    if (this.relativeUrl.indexOf('#') !== -1) {
      this.relativeUrl = this.relativeUrl.substring(0, this.relativeUrl.indexOf('#'));
    }
    // console.log(this.relativeUrl);
    // this.relativeUrl = this.relativeUrl.split('?')[this.router.url.split('/').length - 1];
    if (isPlatformBrowser(this.platformId)) {
      this.commonService.menuItemWidth.next($('.d-flex li:first-child').innerWidth());
    }

  }

  getDeviceInfo() {
    // tslint:disable-next-line:variable-name
    let device_key = '';
    const deviceInfo = this.deviceService.getDeviceInfo();
    // const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();
    // const isDesktopDevice = this.deviceService.isDesktop();
    const device = deviceInfo.os || 'Unknown';
    // if (isMobile) {
    //   device = 'Mobile';
    // } else if (isDesktopDevice) {
    //   device = 'Desktop';
    // } else {
    //   device = 'Tablet';
    // }
    // if (!localStorage.getItem('device_key')) {
    Fingerprint2.get({}, (c) => {
      const values = c.map((component) => {
        return component.value;
      });
      device_key = Fingerprint2.x64hash128(values.join(''), 31);
      let ipAddress = '';

      this.commonService.getDeviceLocation({}).pipe(takeUntil(this.onDestroy$)).subscribe(
        response => {
          if (response) {
            ipAddress = response.ip || '';
          }
        },
        err => {

        },
        () => {
          if (!this.fcm_token) {
            if (isPlatformBrowser(this.platformId) && localStorage) {
              this.fcm_token = localStorage.getItem('fcm_token');
            }
          }
          if (this.fcm_token && this.fcm_token.length > 10) {
            device_key = this.fcm_token;
          }
          this.commonService.getDeviceKey({
            ip_address: ipAddress, ...deviceInfo,
            device,
            device_key,
            fcm_token: this.fcm_token,
          }).pipe(takeUntil(this.onDestroy$)).subscribe(
            res => {
              if (res.code === 200 || res.status === 'success' && isPlatformBrowser(this.platformId)) {
                // localStorage.setItem('device_key', res.data.device_key);
                // this.commonService.setDeviceKey(res.data.device_key);
                let device_key = res.data.device_key;
                if (localStorage.getItem('device_key')) {
                  if (isPlatformBrowser(this.platformId) && localStorage) {
                    device_key = localStorage.getItem('device_key');
                  }
                } else {
                  if (isPlatformBrowser(this.platformId)) {
                    localStorage.setItem('device_key', device_key);
                  }
                }
                this.commonService.setDeviceKey(device_key);
              }
            }
          );
        }
      );
    });
    // }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();

  }
}

export const environment = {
  production: true,
  /*
*live
*/
  // Local

  //own_url: 'http://localhost:4200/',
  //easy_core: 'http://localhost:8000/api/v1/',

  // Live

   easy_core: 'https://core.easy.com.bd/api/v1/',
   own_url: 'https://easy.com.bd/',

  // Dev Server

  // own_url: 'https://easy.com.bd/',
  // easy_core: 'https://easycore.publicdemo.xyz/public/api/v1/',


  google_client_id: '504267677039-2a3dqoid5r8k2lqlip8kvhfe53md0kj4.apps.googleusercontent.com',
  facebook_app_id: '383840946068742',
  firebase: {
    apiKey: 'AIzaSyD2Yl6NsKo6NaWKhxXzg2QOAYV5Z5tPrFw',
    authDomain: 'easy-295007.firebaseapp.com',
    databaseURL: 'https://easy-295007.firebaseio.com',
    projectId: 'easy-295007',
    storageBucket: 'easy-295007.appspot.com',
    messagingSenderId: '504267677039',
    appId: '1:504267677039:web:744bdfabcb82a56803dc9a',
    // measurementId: "G-3FWL7VYRX1"
  }
};

import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CommonService} from '../../services/common.service'; 
import {environment} from '../../../environments/environment.prod';
import {TopupService} from '../../services/recharge/topup.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-corporate-special-pack-amounts',
  templateUrl: './corporate-special-pack-amounts.component.html',
  styleUrls: ['./corporate-special-pack-amounts.component.scss']
})
@NgModule({
  imports: [
      CommonModule
  ], 
})
export class CorporateSpecialPackAmountsComponent implements OnInit, OnDestroy, AfterViewInit {

  public chartData: number[] = [];
  public chartLabel: string[] = [];
  public chartForFlag = undefined;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger = new Subject();
  public fromDate;
  public toDate;
  public pageNumber = 1;
  chartDetailData: any;
  onDestroy$: Subject<void> = new Subject<void>();
  own_url = environment.own_url;
  currentlanguage = 'en';

  constructor(private  commonService: CommonService, private topUpService: TopupService, private dialog: MatDialog, public translate: TranslateService) {
    this.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe((event: LangChangeEvent) => {
      this.currentlanguage = event.lang;
      this.dtTableGenerate();
      this.renderer();
    });
  }

  ngOnInit(): void {
    this.commonService.currentLanguage.pipe(takeUntil(this.onDestroy$)).subscribe(ln => {
      this.currentlanguage = ln;
    });
    if (this.topUpService.data.length <= 0) {
      this.topUpService.topUpPlansAll();
    }
    this.dtTableGenerate();
  }

  dtTableGenerate() {
    const that = this;
    this.dtOptions = {
      oLanguage: {
        sLengthMenu: 'Show _MENU_ entries',
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: false,
      ajax: (dataTablesParameters: any, callback) => {
        this.topUpService.offerRecharge.pipe(takeUntil(this.onDestroy$)).subscribe(
          (resp) => {
            callback({
              recordsTotal: resp.length,
              data: resp,
            });
          },
        );
      },
      columns: [
        {
          className: 'details-control',
          orderable: true,
          data: 'operator_name', render(data, type, row): string {
            if (data.toLowerCase().includes('robi')) {
              return that.currentlanguage === 'en' ? 'Robi Telecom' : 'রবি টেলিকম';
            } else if (data.toLowerCase().includes('airtel')) {
              return that.currentlanguage === 'en' ? 'Airtel Bangladesh' : 'এয়ারটেল বাংলাদেশ';
            } else if (data.toLowerCase().includes('grameen')) {
              return that.currentlanguage === 'en' ? 'Grameenphone' : 'গ্রামীণফোন';
            } else if (data.toLowerCase().includes('banglalink')) {
              return that.currentlanguage === 'en' ? 'Banglalink' : 'বাংলালিংক';
            } else if (data.toLowerCase().includes('teletalk')) {
              return that.currentlanguage === 'en' ? 'Teletalk' : 'টেলিটক';
            } else {
              return data;
            }
          },
          name: 'operator_name',
          defaultContent: '',
        }, {
          className: 'details-control',
          orderable: true,
          data: 'is_for_postpaid', render(data, type, row): string {
            return data ? (that.currentlanguage === 'en' ? 'POSTPAID' : 'পোস্টপেইড') : (that.currentlanguage === 'en' ? 'PREPAID' : 'প্রিপেইড');
          },
          name: 'is_for_postpaid',
          defaultContent: '',
        }, {
          className: 'details-control',
          orderable: true,
          data: 'amount_min', render(data, type, row): string {
            const translate = {
              0: '০',
              1: '১',
              2: '২',
              3: '৩',
              4: '৪',
              5: '৫',
              6: '৬',
              7: '৭',
              8: '৮',
              9: '৯'
            };
            if (that.currentlanguage === 'en') {
              return data + ' tk.';
            } else {
              let tempData = '';
              for (const d of data + '') {
                tempData += translate[d];
              }
              return tempData + ' টাকা';
            }
          },
          name: 'amount_min',
          defaultContent: '',
        }, {
          className: 'details-control',
          orderable: true,
          data: 'offer_type', render(data, type, row): string {
            if (data.toLowerCase() === 'internet') {
              return that.currentlanguage === 'en' ? 'INTERNET' : 'ইন্টারনেট';
            } else if (data.toLowerCase() === 'voice') {
              return that.currentlanguage === 'en' ? 'VOICE' : 'ভয়েস';
            } else if (data.toLowerCase() === 'sms') {
              return that.currentlanguage === 'en' ? 'SMS' : 'খুদেবার্তা';
            } else {
              return that.currentlanguage === 'en' ? 'COMBO' : 'কম্বো';
            }
          },
          name: 'offer_type',
          defaultContent: '',
        }, {
          className: 'details-control',
          orderable: true,
          data: 'description', render(data, type, row): string {
            return that.currentlanguage === 'en' ? data : row.description_bn;
          },
          name: 'description',
          defaultContent: '',
        }, {
          className: 'details-control',
          orderable: true,
          data: 'validity', render(data, type, row): string {
            return that.currentlanguage === 'en' ? data : row.validity_bn;
          },
          name: 'validity',
          defaultContent: '',
        },
      ],
      responsive: true,
      lengthMenu: [[10, 20, 50, 100, -1], [10, 20, 50, 100, 'All']],
      order: [[3, 'desc']],
      columnDefs: [
        {
          orderable: false
        },
        {type: 'date', targets: 3}
      ],
      infoCallback: (settings, start, end, max, total, pre) => {
        this.pageNumber = start;
      }
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  makeSerial() {
    setTimeout(() => {
      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.on('order.dt search.dt', () => {
            dtInstance.column(0,
              {search: 'applied', order: 'applied'})
              .nodes().each((cell, i) => {
              cell.innerHTML = i + 1;
            });
          });
        });
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
    this.dtTrigger.unsubscribe();
  }

  renderer(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear();
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }

}
